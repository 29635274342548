<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <div>
          <b>
            * Please take in consideration editing holidays
            days might take a few seconds to process.
          </b>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <router-link
              tag="md-button"
              class="md-just-icon md-round md-primary pull-right"
              :to="{name: 'NewHolidayDays'}"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Holiday Day</md-tooltip>
            </router-link>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              :search="false"
              @changed="getHolidays"
              @onEdit="onEdit"
              @onDelete="onDelete"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import moment from 'moment';

export default {
  components: {
    VueTable,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'holiday_day_id',
            mask: 'id',
            sortable: true,
          },
          {
            title: 'date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'description',
            sortable: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit Holiday',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Holiday',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
      loading: false,
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getHolidays(params) {
      this.request(this.$API.HOLIDAY_DAYS.paginableUrl(params), 'get', null, ({ data }) => {
        this.vTable.values = data;
      });
    },
    onEdit(item, isMiddleClick) {
      this.openLink({ name: 'EditHolidayDay', params: { id: item.holiday_day_id } }, isMiddleClick);
    },
    onDelete(item) {
      this.fireConfirm(
        'Deleting Holiday',
        `Are you sure you want to delete this Holiday Day (${moment(item.date).format('DD-MM-YYYY')})?`,
      )
        .then(() => {
          this.loading = true;
          this.request(`${this.$API.HOLIDAY_DAYS}${item.holiday_day_id}`, 'delete', {}, () => {
            this.fireSuccess('Holiday deleted successfully.');
            this.loading = false;
            // Reset table after response
            this.$refs.vtable.init();
          });
        });
    },
  },
};
</script>

<style scoped>
</style>
